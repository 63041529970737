import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMd } from "@fortawesome/free-solid-svg-icons"

import Hero from "layout/Hero"
import Container from "layout/Container"

import styles from "../utils/staticPages.module.scss"
import { GATSBY_HAS_AUTH_ENABLED } from "gatsby-env-variables"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "landing_page/landing_rx-beat.png" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carePlusLogo: file(relativePath: { eq: "logos/logo_careplus.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const heart = data.heart.childImageSharp.fluid.src
  const carePlusLogo = data?.carePlusLogo?.childImageSharp?.fixed

  const IS_AUTH_ENABLED = GATSBY_HAS_AUTH_ENABLED === "enabled" ? true : false

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container isCentered desktop={10} fullhd={8}>
        <Img fixed={carePlusLogo} />

        <p className="subtitle is-size-5 pb-1 pb-3-mobile pt-1">
          This program provides treatment assistance to{" "}
          <br className="is-hidden-mobile" />
          patients. It aims to improve patients' health
          <br className="is-hidden-mobile" />
          through greater convenience and lower cost.
        </p>

        <div className="buttons">
          <Link
            to="/epharmacy/order"
            className={classNames(
              "button is-primary is-medium",
              styles["home__buttonIsFullwidth"]
            )}
          >
            Order Medicines
          </Link>
          {!IS_AUTH_ENABLED && (
            <Link
              to="/enroll-patient"
              className={classNames(
                "button is-primary is-medium is-outlined",
                styles["home__buttonIsFullwidth"]
              )}
            >
              Enroll as Patient
            </Link>
          )}
        </div>
        <div className={classNames("is-flex mt-2", styles["drEnrollment"])}>
          <span className="icon has-text-primary">
            <FontAwesomeIcon icon={faUserMd} size="2x" />
          </span>
          <span className="ml-1">
            Improve your patients’ health.{" "}
            <Link to="/enroll-doctor" className="has-text-weight-bold">
              Register as partner doctor.
            </Link>
          </span>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
